import { ChangeEvent, useContext, useEffect, useState } from "react";
import { WButton } from "../../../wrapper/button/button";
import { WInput } from "../../../wrapper/input/input";
import styles from "./register.module.scss";
import { useTranslation } from 'react-i18next';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";
import { useToast } from "@chakra-ui/react";
import { UserContext } from "../../../contexts/user-context";
import { useNavigate } from "react-router";
import { HelperService } from "../../../services/helper";
import LanguageIconButton from "../../../components/language-icon-button/language-icon-button";


export default function Register() {

    const { t } = useTranslation();
    const { user } = useContext(UserContext)!;
    const toast = useToast()
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const isButtonDisabled = !email || !password || password !== confirmPassword;

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, type: string) => {
        const value = e.target.value;
        switch (type) {
            case "email":
                setEmail(value);
                break;
            case "password":
                setPassword(value);
                break;
            default:
                setConfirmPassword(value)
                break;
        }
    }

    const handleRegister = async () => {
        setLoading(true);

        try {
            await createUserWithEmailAndPassword(auth, email, password);
            navigate("/")
        } catch (error: any) {
            const message = HelperService.getAuthErrorMessage(t, error.code);

            toast({
                title: message,
                status: 'error',
                duration: 2000,
                position: "bottom",
            })
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        if (user) {
            navigate("/")
        }
    }, [user, navigate]);

    return (
        <div className={styles["container"]}>
            <div className={styles["left"]}>
                <LanguageIconButton className={styles["language"]} />
                <div className={styles["content"]}>

                    <h1 className={styles["title"]}>{t("join_us")}</h1>

                    <WInput
                        placeholder={t("email")}
                        onChange={e => handleInputChange(e, "email")}
                    />

                    <WInput
                        type="password"
                        placeholder={t("password")}
                        onChange={e => handleInputChange(e, "password")}
                    />

                    <WInput
                        type="password"
                        placeholder={t("confirm_password")}
                        onChange={e => handleInputChange(e, "confirm_password")}
                    />

                    <WButton
                        isLoading={loading}
                        type="submit"
                        isDisabled={isButtonDisabled}
                        onClick={handleRegister}
                    >
                        {t("register")}
                    </WButton>
                    <span className={styles["continue"]}>{t("by_continue")}</span>
                </div>
            </div>
            <div className={styles["right"]}>
                <img src="images/headset.png" alt="" />
            </div>
        </div>
    );
}