import { useContext, useEffect, useRef, useState } from "react";
import styles from "./player.module.scss";
import { WIcon } from "../../wrapper/icon/icon";
import { WButton } from "../../wrapper/button/button";
import { WSlider, WSliderFilledTrack, WSliderThumb, WSliderTrack } from "../../wrapper/slider/slider";
import { debounce } from "lodash";
import { HelperService } from "../../services/helper";
import { WModal, WModalContent } from "../../wrapper/modal/modal";
import { IMusic } from "../../interfaces/music";
import { IMusicContext, MusicContext } from "../../contexts/music-context";


// Custom hook for audio player logic
const useAudioPlayer = (initialVolume: number) => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const [volume, setVolume] = useState(initialVolume);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const playPauseAudio = () => {
        if (audioRef.current) {
            const isPaused = audioRef.current.paused;
            isPaused ? audioRef.current.play() : audioRef.current.pause();
            setIsPlaying(isPaused);
        }
    };

    const onVolumeChange = debounce((value: number) => {
        setVolume(value);
        localStorage.setItem("volume", value.toString());
        if (audioRef.current) audioRef.current.volume = value;
    }, 10);

    const updateCurrentTime = () => {
        if (audioRef.current) setCurrentTime(audioRef.current.currentTime);
    };

    const handleDurationChange = (value: number) => {
        if (audioRef.current) {
            audioRef.current.currentTime = value;
            setCurrentTime(value);
        }
    };

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume;
            audioRef.current.onloadedmetadata = () => setDuration(audioRef.current!.duration);
        }
    }, [volume]);

    return {
        audioRef,
        volume,
        currentTime,
        duration,
        isPlaying,
        playPauseAudio,
        onVolumeChange,
        updateCurrentTime,
        handleDurationChange,
        setIsPlaying,
    };
};

// Player component
export default function Player() {
    const initialVolume = Number(localStorage.getItem("volume") || "1");
    const {
        audioRef,
        volume,
        currentTime,
        duration,
        isPlaying,
        playPauseAudio,
        onVolumeChange,
        updateCurrentTime,
        handleDurationChange,
        setIsPlaying,
    } = useAudioPlayer(initialVolume);

    const [modalVisible, setModalVisible] = useState(false);

    const prevMusic: IMusic = {
        src: "https://cdn.pixabay.com/audio/2023/06/17/audio_3e1e6e1c3f.mp3",
        image: "https://picsum.photos/200/300",
        name: "Kaçak",
        artist: { name: "Bilmirem", id: "" },
        loop: false
    };

    const nextMusic: IMusic = {
        src: "https://cdn.pixabay.com/audio/2021/10/22/audio_067a847e80.mp3",
        image: "https://picsum.photos/200/300",
        name: "Hoşgeldin",
        artist: { name: "Salatalık", id: "" },
        loop: false
    };

    const { music, setMusic } = useContext(MusicContext) as IMusicContext;
    

    // Handle previous track click
    const handlePrevClick = () => {
        if (!audioRef.current) return;
        if (currentTime > 3 || prevMusic.src === audioRef.current.src) {
            audioRef.current.currentTime = 0;
        } else {
            switchTrack(prevMusic);
        }
    };

    // Handle next track click
    const handleNextClick = () => {
        if (audioRef.current && audioRef.current.src !== nextMusic.src) {
            switchTrack(nextMusic);
        }
    };

    // Handle track switch logic
    const switchTrack = (newMusic: IMusic) => {
        setMusic(newMusic);
        audioRef.current?.load();
        setTimeout(() => {
            audioRef.current?.play();
            setIsPlaying(true)
        }, 1);
    };

    // Handle end of track
    const handleEnd = () => {
        if (!audioRef.current) return;
        if (audioRef.current.src !== nextMusic.src) {
            switchTrack(nextMusic);
        } else {
            setIsPlaying(false);
        }
    };

    // Toggle loop for current track
    const handleLoopClick = () => {
        setMusic((prev) => ({ ...prev!, loop: !prev!.loop }));
    };

    const displayModal = () => {
        if (window.innerWidth > 768) return;
        setModalVisible(true)
    };

    if(!music) return <div hidden></div>

    return (
        <div className={styles["container"]} onClick={displayModal}>
            <WModal isOpen={modalVisible} onClose={() => setModalVisible(false)} motionPreset="slideInBottom" size="full">
                <WModalContent className={styles["modal"]}>
                    <WButton className={styles["close-button"]} onClick={() => setModalVisible(false)}>
                        <WIcon name="keyboard_arrow_down" />
                    </WButton>

                    <img src={music.image} alt={music.name} />

                    {/* Track Info */}
                    <TrackInfo imageVisible={false} music={music} />

                    {/* Duration Modal */}
                    <DurationSlider
                        currentTime={currentTime}
                        duration={duration}
                        onDurationChange={handleDurationChange}
                        className={styles["duration-modal"]}
                    />

                    {/* Modal Controls */}
                    <ModalControls
                        isPlaying={isPlaying}
                        playPauseAudio={playPauseAudio}
                        handlePrevClick={handlePrevClick}
                        handleNextClick={handleNextClick}
                        handleLoopClick={handleLoopClick}
                        loopActive={music.loop}
                    />
                </WModalContent>
            </WModal>

            {/* Hidden Audio Element */}
            <audio
                hidden
                preload="metadata"
                src={music.src}
                ref={audioRef}
                onTimeUpdate={updateCurrentTime}
                onEnded={handleEnd}
                loop={music.loop}
            />

            {/* Track Info */}
            <TrackInfo music={music} />

            {/* Player Controls */}
            <PlayerControls
                isPlaying={isPlaying}
                playPauseAudio={playPauseAudio}
                handlePrevClick={handlePrevClick}
                handleNextClick={handleNextClick}
                handleLoopClick={handleLoopClick}
                loopActive={music.loop}
                currentTime={currentTime}
                duration={duration}
                onDurationChange={handleDurationChange}
            />

            {/* Volume Slider */}
            <VolumeSlider volume={volume} onVolumeChange={onVolumeChange} />
        </div>
    );
}

// Subcomponents for better separation

const DurationSlider = ({ currentTime, duration, onDurationChange, className }: any) => (
    <div className={className} onClick={e => e.stopPropagation()}>
        <span>{HelperService.secondsToTime(currentTime)}</span>
        <WSlider min={0} max={duration} value={currentTime} step={1} onChange={onDurationChange}>
            <WSliderTrack>
                <WSliderFilledTrack />
            </WSliderTrack>
            <WSliderThumb />
        </WSlider>
        <span>{HelperService.secondsToTime(duration)}</span>
    </div>
);

const ModalControls = ({ isPlaying, playPauseAudio, handlePrevClick, handleNextClick, handleLoopClick, loopActive }: any) => (
    <div className={styles["modal-buttons"]} onClick={e => e.stopPropagation()}>
        <WIcon name="favorite" />
        <WIcon name="skip_previous" onClick={handlePrevClick} />
        <WButton onClick={playPauseAudio} className={styles["button"]}>
            <WIcon name={isPlaying ? "pause" : "play_arrow"} />
        </WButton>
        <WIcon name="skip_next" onClick={handleNextClick} className={styles["next"]} />
        <WIcon name="repeat" onClick={handleLoopClick} className={loopActive ? styles["active"] : ""} />
    </div>
);

const TrackInfo = ({ music, imageVisible = true }: any) => (
    <div className={styles["track"]}  >
        {imageVisible && <img src={music.image} alt={music.name} />}
        <div className={styles["name-and-artist"]}>
            <span>{music.name}</span>
            <p>{music.artist.name}</p>
        </div>
    </div>
);

const PlayerControls = ({
    isPlaying,
    playPauseAudio,
    handlePrevClick,
    handleNextClick,
    handleLoopClick,
    loopActive,
    currentTime,
    duration,
    onDurationChange,
}: any) => (
    <div className={styles["controls"]}  >
        <div className={styles["buttons"]} onClick={e => e.stopPropagation()}>
            <WIcon name="favorite" />
            <WIcon name="skip_previous" onClick={handlePrevClick} />
            <WButton onClick={playPauseAudio} className={styles["button"]}>
                <WIcon name={isPlaying ? "pause" : "play_arrow"} />
            </WButton>
            <WIcon name="skip_next" onClick={handleNextClick} className={styles["next"]} />
            <WIcon name="repeat" onClick={handleLoopClick} className={loopActive ? styles["active"] : ""} />
        </div>
        <DurationSlider className={styles["duration"]} currentTime={currentTime} duration={duration} onDurationChange={onDurationChange} />
    </div>
);

const VolumeSlider = ({ volume, onVolumeChange }: any) => (
    <div className={styles["actions"]}>
        <div className={styles["volume-wrapper"]} onClick={e => e.stopPropagation()}>
            <WIcon name={volume === 0 ? "volume_mute" : "volume_up"} />
            <WSlider min={0} max={1} value={volume} step={0.01} onChange={onVolumeChange} className={styles["volume"]}>
                <WSliderTrack>
                    <WSliderFilledTrack />
                </WSliderTrack>
                <WSliderThumb />
            </WSlider>
        </div>
    </div>
);
