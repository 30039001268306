import * as React from "react";
import { useState, createContext } from "react";
import { IMusic } from "../interfaces/music";


export interface IMusicContext {
    music: IMusic | null;
    setMusic: React.Dispatch<React.SetStateAction<IMusic | null>>;
}

export const MusicContext = createContext<IMusicContext | null>(null);

export const MusicContextProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {

    const [music, setMusic] = useState<IMusic | null>({
        src: "https://cdn.pixabay.com/audio/2023/06/17/audio_3e1e6e1c3f.mp3",
        image: "https://picsum.photos/200/300",
        name: "Kaçak",
        artist: { name: "Bilmirem", id: "" },
        loop: false
    });

    return (
        <MusicContext.Provider
            value={{
                music,
                setMusic,
            }}
        >
            {children}
        </MusicContext.Provider>
    );
};
