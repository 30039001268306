import { ChangeEvent, useContext, useEffect, useState } from "react";
import { WButton } from "../../../wrapper/button/button";
import { WInput } from "../../../wrapper/input/input";
import styles from "./login.module.scss";
import { useTranslation } from 'react-i18next';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";
import { useToast } from "@chakra-ui/react";
import { UserContext } from "../../../contexts/user-context";
import { useNavigate } from "react-router";
import { HelperService } from "../../../services/helper";
import LanguageIconButton from "../../../components/language-icon-button/language-icon-button";

export default function Login() {

    const { t } = useTranslation();
    const { user } = useContext(UserContext)!;
    const toast = useToast()
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const isButtonDisabled = !email || !password;

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, isMail: boolean) => {
        const value = e.target.value;
        isMail ? setEmail(value) : setPassword(value);
    }

    const handleLogin = async () => {
        setLoading(true);

        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate("/")
        } catch (error: any) {
            const message = HelperService.getAuthErrorMessage(t, error.code);
            console.log(error.code);

            toast({
                title: message,
                status: 'error',
                duration: 2000,
                position: "bottom"
            })
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        if (user) {
            navigate("/")
        }
    }, [user, navigate]);

    return (
        <div className={styles["container"]}>
            <div className={styles["left"]}>

                <LanguageIconButton className={styles["language"]} />

                <div className={styles["content"]}>

                    <h1 className={styles["title"]}>{t("welcome")}</h1>

                    <WInput
                        placeholder={t("email")}
                        onChange={e => handleInputChange(e, true)}
                    />

                    <WInput
                        type="password"
                        placeholder={t("password")}
                        onChange={e => handleInputChange(e, false)}
                    />

                    <WButton
                        isLoading={loading}
                        type="submit"
                        isDisabled={isButtonDisabled}
                        onClick={handleLogin}
                    >
                        {t("login")}
                    </WButton>
                    <span className={styles["continue"]}>{t("by_continue")}</span>
                </div>
            </div>
            <div className={styles["right"]}>
                <img src="images/headset.png" alt="" />
            </div>
        </div>
    );
}