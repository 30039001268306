export const en = {
    translation: {
        "welcome": "Welcome Back",
        "join_us": "Join Us",
        "email": "E-Mail",
        "password": "Password",
        "confirm_password": "Confirm password",
        "login": "Login",
        "register": "Register",
        "by_continue": "By continuing, you agree to Riwai’s Terms of Service and Privacy Policy.",
        "error_email_already_in_use": "Email already used. Go to login page.",
        "error_wrong_password": "Wrong email/password combination.",
        "error_user_not_found": "No user found with this email.",
        "error_user_disabled": "User disabled.",
        "error_too_many_requests": "Too many requests to log into this account.",
        "error_operation_not_allowed": "Server error, please try again later.",
        "error_invalid_email": "Email address is invalid.",
        "error_weak_password": "Password is too weak.",
        "error_invalid_verification_code": "Invalid verification code.",
        "error_invalid_verification_id": "Invalid verification ID.",
        "error_credential_already_in_use": "This credential is already associated with a different user account.",
        "error_requires_recent_login": "This operation is sensitive and requires recent authentication. Log in again before retrying this request.",
        "error_missing_email": "Email is required but missing.",
        "error_email_already_exists": "Email already exists.",
        "error_phone_number_already_exists": "Phone number already exists.",
        "error_invalid_credential": "The credentials are invalid.",
        "login_failed": "Login failed. Please try again.",
        "play": "Play",
        "stop": "Stop",
        "next": "Next",
        "previous": "Previous",
        "loop": "Loop",
        "best_of_the_week": "Best of the Week"
    }
};
