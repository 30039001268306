import styles from "./home.module.scss";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";

export default function Home() {

    const { t } = useTranslation();
    const settings = {
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        dots: false,

    };

    return (
        <div className={styles["container"]}>
            <div className={styles["bow-container"]}>
                <h1 className={styles["bow-title"]}>{t("best_of_the_week")}</h1>
                <div className={styles["buttons"]}>

                </div>
            </div>
            <Slider {...settings}>
                <div>
                    <h3>1</h3>
                </div>
                <div>
                    <h3>2</h3>
                </div>
                <div>
                    <h3>3</h3>
                </div>
                <div>
                    <h3>4</h3>
                </div>
                <div>
                    <h3>5</h3>
                </div>
                <div>
                    <h3>6</h3>
                </div>
            </Slider>
        </div>
    )
}